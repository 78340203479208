.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.scrollBar {
  max-height: 100px;
  overflow-y: auto;
}

.form-check-input {
  /* width: 2.2rem !important;
  height: 2.2rem !important; */
}

.form-input-custom {
  width: 1rem !important;
  height: 1rem !important;
}

.hideVerticalScrollbar {
  overflow-y: hidden;
}

.body
  > div
  > div.layout-container
  > div
  > div
  > div.container-xxl.flex-grow-1.container-p-y.pad-null {
  padding-bottom: 0rem !important;
  padding-top: 0rem !important;
}

.errorMessage {
  color: red !important;
  white-space: initial !important;
}

.bg-label-custom-one {
  background-color: #aba581 !important;
  color: #f5ed07 !important;
}

.bg-label-custom-two {
  background-color: #699dbf !important;
  color: #1a1ab9 !important;
}

.bg-label-custom-three {
  background-color: #e8fadf !important;
  color: #697a8d !important;
}

.bg-label-custom-four {
  background-color: #e7d5b0 !important;
  color: #e400ff !important;
}

.card-body {
  flex: 1 1 auto !important;
  margin: 0 auto;
  text-align: center;
  padding: 1.5rem 0.5rem !important;
}

.linkPointer {
  cursor: pointer;
}

.spanDisable {
  pointer-events: none;
}

.whiteSpace {
  white-space: normal;
}

.cardHeight {
  display: block;
  min-height: 46px !important;
  cursor: pointer;
}

.reportCardHeight {
  display: block;
  min-height: 46px !important;
}

.cardAlign{
  display: flex;
  align-items: center;
  font-size: 12px;
}

.cardBox {
  display: block;
  min-height: 46px !important;
}

.login-page .card-body {
  width: 100%;
  padding: 30px 50px !important;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.color-indicator-grid {
  display: flex;
  justify-content: end;
}

.color-indicator-grid .section {
  display: flex;
  align-items: center;
  margin: 0 20px 0 0;
}

.color-indicator-grid .section .color-box {
  padding: 10px;
  margin: 0 5px 0 0;
  width: 15px;
}

.color-indicator-grid .section .color-box.green {
  background-color: #696cff;
}

.color-indicator-grid .section .color-box.blue {
  background-color: #71dd37;
}

.color-indicator-grid .section .color-box.yellow {
  background-color: #03c3ec;
}

.sub-thead th {
  background: #eceef1;
}

.table-container {
  display: flex;
  align-items: flex-start;
}

.table-responsive {
  flex-grow: 1;
  margin-right: 16px; /* Adjust the margin as needed */
  overflow-x: hidden; /* Hide horizontal scroll */
}

/* Define faint colors with reduced opacity */
.bg-danger-faint {
  background-color: rgba(220, 53, 69, 0.5); /* Adjust opacity as needed */
}

.bg-warning-faint {
  background-color: rgba(255, 193, 7, 0.5); /* Adjust opacity as needed */
}

.bg-success-faint {
  background-color: rgba(40, 167, 69, 0.5); /* Adjust opacity as needed */
}

.text-black {
  color: black;
}

.font-bold {
  font-weight: bold;
}

.comment-error {
  font-size: 14px; /* Adjust the font size as needed */
  /* Add any additional styling if required */
}
